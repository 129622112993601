import Typewriter from "typewriter-effect/dist/core";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";



export const initTypewriter = (pageName) => {

  const storedLanguage = localStorage.getItem('language');

  if(pageName === 'Home'){

    const introTypewriterEl = document.getElementById("typewriter-intro");
    const portfolioTypewriterEl = document.getElementById("typewriter-portfolio");
    const careersTypewriterEl = document.getElementById("typewriter-careers");

    let introStrings = ["creating", "supporting", "empowering"];
    let careersStrings = ["ClickUp", "Slack", "Tolstoy"];
    let portfolioStrings = ["ventures", "teams", "founders"];

    // if(storedLanguage === 'pl'){
    //   introStrings = ["tworzeniem", "wspieraniem", "wzmacnianiem"];
    //   careersStrings = ["ClickUp", "Slack", "Tolstoy"];
    //   portfolioStrings = ["biznesy", "zespoły", "founderów"];
    // }

    const introTypewriter = new Typewriter(introTypewriterEl, {
      strings: introStrings,
      autoStart: true,
      loop: true,
    });

    const careersTypewriter = new Typewriter(careersTypewriterEl, {
      strings: careersStrings,
      autoStart: true,
      loop: true,
    });

    
    const portfolioTypewriter = new Typewriter(portfolioTypewriterEl, {
      strings: portfolioStrings,
      autoStart: true,
      loop: true,
    });

    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      onEnter: () => unpauseTypewriter(introTypewriter),
      onLeave: () => pauseTypewriter(introTypewriter),
      onEnterBack: () => unpauseTypewriter(introTypewriter),
      onLeaveBack: () => pauseTypewriter(introTypewriter)
    })

    ScrollTrigger.create({
      trigger: '#RS-header-typewriter-careers',
      onEnter: () => unpauseTypewriter(careersTypewriter),
      onLeave: () => pauseTypewriter(careersTypewriter),
      onEnterBack: () => unpauseTypewriter(careersTypewriter),
      onLeaveBack: () => pauseTypewriter(careersTypewriter)
    })


    ScrollTrigger.create({
      trigger: '#RS-header-typewriter-portfolio',
      onEnter: () => unpauseTypewriter(portfolioTypewriter),
      onLeave: () => pauseTypewriter(portfolioTypewriter),
      onEnterBack: () => unpauseTypewriter(portfolioTypewriter),
      onLeaveBack: () => pauseTypewriter(portfolioTypewriter)
    })

  }

  if(pageName === 'Founder'){

    const introTypewriterEl = document.getElementById("typewriter-founder-intro");
    const workshopsTypewriterEl = document.getElementById("typewriter-founder-workshops");


    let introStrings = ["founder", "entrepreneur", "innovator", "pioneer"];

    // if(storedLanguage === 'pl'){
    //   introStrings = ["founderze", "przedsiębiorco", "innowatorze", "pionierze"];
    // }


    const introTypewriter = new Typewriter(introTypewriterEl, {
      strings: introStrings,
      autoStart: true,
      loop: true,
    });

    const workshopsTypewriter = new Typewriter(workshopsTypewriterEl, {
      strings: ["OO", "MO", "PO", "TO"],
      autoStart: true,
      loop: true,
    });

    ScrollTrigger.create({
      trigger: '#RS-section-founder-intro',
      onEnter: () => unpauseTypewriter(introTypewriter),
      onLeave: () => pauseTypewriter(introTypewriter),
      onEnterBack: () => unpauseTypewriter(introTypewriter),
      onLeaveBack: () => pauseTypewriter(introTypewriter)
    })

    ScrollTrigger.create({
      trigger: '#RS-section-founder-workshops',
      onEnter: () => unpauseTypewriter(workshopsTypewriter),
      onLeave: () => pauseTypewriter(workshopsTypewriter),
      onEnterBack: () => unpauseTypewriter(workshopsTypewriter),
      onLeaveBack: () => pauseTypewriter(workshopsTypewriter)
    })

  }


  function unpauseTypewriter(tw){
    tw.stop().start();
  }

  function pauseTypewriter(tw){
    tw.stop();
  }



};

